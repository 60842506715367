
window.addEventListener('DOMContentLoaded', () => {
    
    window.addEventListener('turbolinks:load', () => {

    document.querySelectorAll(".tile").forEach(elem => elem.addEventListener("click", () => {
        
        const tile_info = elem.querySelector(".tile_info");
        tile_info.style.height = (tile_info.style.height !== '300px') ? '300px' : '0px';


    }));


});

});


