window.addEventListener('DOMContentLoaded', () => {
    
window.addEventListener('turbolinks:load', () => {
/* Highlight Current Page in Navigation */

const currentPage = window.location.pathname;

const navLinks = document.querySelectorAll(".primary_navigation a").forEach(link => {

    if (link.pathname === currentPage) {
        link.classList.add("active_page");
    }
       
})

/* Mobile Navigation Drop Down Menu */

const primaryNav = document.querySelector(".primary_navigation");
const navToggle = document.querySelector(".mobile_nav_toggle");


navToggle.addEventListener('click', () => {
    const visibility = primaryNav.getAttribute("data-visible");

    if (visibility === "false") {
        primaryNav.setAttribute("data-visible", true);
        navToggle.setAttribute("aria-expanded", true);
    }
    else {
        primaryNav.setAttribute("data-visible", false);
        navToggle.setAttribute("aria-expanded", false);
    }
})



});

});
